import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useStateIfMounted } from "use-state-if-mounted"
import NavigationBar from "../HeaderComponents/NavigationBar"
import _Calculator from "../Calculator/Calculator"
import { CustomDot } from "../Reusable/dot-line"
import Legal from "./Legal"
import { Banner } from "./Banner"
import HeaderBackground from "../../images/header-background.png"
import { StoreLinks } from "./StoreLinks"
import rsr from "react-string-replace"
import { graphql, useStaticQuery } from "gatsby"

export const Header = ({ props, propsLink, title, _title }) => {
  const data = useStaticQuery(graphql`
    query {
      headerBackground: file(relativePath: { eq: "header-background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imagesBg = data.headerBackground.childImageSharp.fluid

  return (
    <MainPageHeader style={{ paddingTop: "80px" }}>
      {/* <NavigationBar
        props={props}
        propsLink={propsLink}
        parentCallback={statusNavbar}
      /> */}
      <Container className="pt-5 position-relative">
        <MainImage fluid={imagesBg} alt="header background" loading="eager" />
        <Row>
          <Col md={7}>
            <TitleH1 {..._title}>{title || props.Title}</TitleH1>
          </Col>
          <CustomColCalc md={5}>
            <Calculator propsLink={propsLink} />
            <LegalDiv>
              <Legal props={props} />
              <StoreLinks className="mt-3" />
            </LegalDiv>
          </CustomColCalc>
        </Row>
      </Container>
    </MainPageHeader>
  )
}

const MainPageHeader = styled.div`
  min-height: 800px;
  padding-bottom: 40px;
  @media (max-width: 576px) {
    padding-bottom: 150px !important;
  }
`

const MainImage = styled(Img)`
  position: absolute !important;
  width: 1000px;
  left: -20% !important;
  top: 40% !important;
`

const TitleH1 = styled.h2`
  font-size: 38px;
  color: #909098;
  padding-right: 10px;
  font-family: "Typefez-black";
  @media (min-width: 768px) {
    text-align: left;
  }
`

const LegalDiv = styled.div`
  position: absolute;
  top: 96%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 70px;
  align-items: center;
  @media (max-width: 1200px) {
    padding-left: 0px;
  }
  @media (max-width: 768px) {
    padding-left: 130px;
  }
  @media (max-width: 576px) {
    padding-left: 10px;
  }
`

const CustomColCalc = styled(Col)`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  @media (max-width: 576px) {
    align-items: center;
    margin-top: 50px;
  }
`

const Calculator = styled(_Calculator)`
  position: "absolute";
  z-index: 10;
`
